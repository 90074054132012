//var mqtt_api_url = "http://ai.fondcard.net/open/mqtt/api.php?method=";
import {
    Message,
    MessageBox
} from 'element-ui'
// var mqtt_api_url = "https://web.fondcard.net/open/mqtt/api.php?method=";
console.log(CONFIG);
var door_open = CONFIG.baseUrl + 'open/mqtt/api.php?method='+"mqtt.door.open";
var clear_device = CONFIG.baseUrl + 'open/mqtt/api.php?method='+"mqtt.key.clear";
var update_device = CONFIG.baseUrl + 'open/mqtt/api.php?method='+"mqtt.key.update";
var reset_device = CONFIG.baseUrl + 'open/mqtt/api.php?method='+"mqtt.device.reset";
var client = null;

var language =  window.localStorage.getItem('language')

import jq from 'jquery'
var loginObj=JSON.parse(window.localStorage.getItem('user'));

export function startMQTTClient() {
    client = new MQTTClient();
    client.connect({
        domain: "ai.fondcard.net",
        userId: loginObj.manager_id, //sessionStorage.getItem("manager_id"),
        userName: loginObj.mqtt_serial, //sessionStorage.getItem('mqtt_serial'),
        password: loginObj.mqtt_password, //sessionStorage.getItem('mqtt_password'),
        useSSL: false,
        onSuccess: function () {
            console.log("连接成功");
        },
        onFaceAction: onMQTTFaceAction,
        onMessageArrived: onMQTTMessageArrived,
        onConnectionLost: function () {
            console.log("连接断开");
            client = null;
        },
        onFail: function (res) {
            console.log("连接失败", res);
            client = null;
        },
        trace: function (msg) {
            console.log(JSON.stringify(msg));
        }
    });
}

export function onMQTTFaceAction(serial, cmd, result) {
    if (result == FaceActionResult.Success) {
        console.log('人脸登记成功.');
    } else if (result == FaceActionResult.Fail) {
        console.log('登记失败.');
    } else if (result == FaceActionResult.Timeout) {
        //alertText('登记超时.');        
        Message({
            type: 'warning',
            message: '登记超时!'
          })
    } else if (result == FaceActionResult.Repeat) {
        //alertText('重复登记.');
        Message({
            type: 'warning',
            message: '重复登记!'
          })
    }
}

export function onMQTTMessageArrived(serial, cmd, data1, data2) {
    console.log("收到消息:" + cmd);
    if (serial == "1000" || serial == "2000") {
    }
}

export function clearDevice(serial, password) {
    console.log("clearDevice:" + serial);
    // if (client) {
    //
    //     client.openDoor(serial, password);
    // }

    jq.ajax({
        url: clear_device,
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: {
            serial :serial,
            password:password,
            userId: loginObj.manager_id//$.storageData.ManagerId() //sessionStorage.getItem("manager_id")
        },
        success: function (res) {
            if(res.code==0){
                Message({
                    type: 'success',
                    message: '清除设备成功!'
                  })
            }else{
                Message({
                    type: 'warning',
                    message: '清除设备失败!'
                  })
            }
        }
    });
}

export function updateDevice(serial, password) {
    // let UserInfo =
    // console.log("updateDevice:" + UserInfo);
    // if (client) {
    //
    //     client.openDoor(serial, password);
    // }
    var message1,message2
    if(language =='en'){
        message1='Update device successfully'
        message2='Failed to update device'
    }else{
        message1 = "更新设备成功!"
        message2='更新设备失败!'
    }

    jq.ajax({
        url: update_device,
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: {
            serial :serial,
            password:password,
            userId: loginObj.manager_id//$.storageData.ManagerId() //sessionStorage.getItem("manager_id")
        },
        success: function (res) {
            if(res.code==0){                
                Message({
                    type: 'success',
                    message: message1
                  })
            }else{
                Message({
                    type: 'warning',
                    message: message2
                  })
            }
        }
    });
}

export function openDoor(serial, password) {
    console.log("openDoor:" + serial);
    // if (client) {
    //
    //     client.openDoor(serial, password);
    // }
    var message3,message4
    if(language =='en'){
        message3='Remote door opened successfully'
        message4='Remote door opening failed'
    }else{
        message3 = "远程开门成功!"
        message4='远程开门失败!'
    }

    jq.ajax({
            url: door_open,
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: {
                serial :serial,
                password:password,
                userId: loginObj.manager_id//$.storageData.ManagerId() //sessionStorage.getItem("manager_id")
            },
            success: function (res) {
                if(res.code==0){
                    Message({
                        type: 'success',
                        message: message3
                      })
                }else{
                    Message({
                        type: 'warning',
                        message: message4
                      })
                }
            }
        });
}

export function resetDevice(serial, password) {
    console.log("resetDevice:" + serial);
    // if (client) {
    //
    //     client.openDoor(serial, password);
    // }
    var message5,message6
    if(language =='en'){
        message5='Device restart successful'
        message6='Device restart failed'
    }else{
        message5 = "设备重启成功!"
        message6='设备重启失败!'
    }
    jq.ajax({
            url: reset_device,
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: {
                serial :serial,
                password:password,
                userId: loginObj.manager_id//$.storageData.ManagerId() //sessionStorage.getItem("manager_id")
            },
            success: function (res) {
                if(res.code==0){
                    Message({
                        type: 'success',
                        message: message5
                      })
                }else{
                    Message({
                        type: 'warning',
                        message:message6
                      })
                }
            }
        });
}