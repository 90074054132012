import { render, staticRenderFns } from "./deviadd.vue?vue&type=template&id=5670319a&scoped=true&"
import script from "./deviadd.vue?vue&type=script&lang=js&"
export * from "./deviadd.vue?vue&type=script&lang=js&"
import style0 from "./deviadd.vue?vue&type=style&index=0&id=5670319a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5670319a",
  null
  
)

export default component.exports