<template>
    <div class="mauntacont">
        <el-dialog :title="$t('devtable.ggwhsb')" append-to-body width="35%" :visible.sync="dialogFormVisible">
            <el-form :model="form">    
                <div class="mainatai_row">
                    <div class="maiinput">
                        <p>{{$t('devtable.ysbxlh')}}</p>
                        <el-input :placeholder="$t('devtable.qxz')" size="small" v-model="form.old_serial"  :disabled="true"></el-input>
                    </div>
                    <div class="maiinput">
                        <p>{{$t('devtable.xshxlh')}}</p>
                        <el-input  :placeholder="$t('devtable.qxz')" size="small" v-model="form.new_serial"  clearable></el-input>
                    </div>
                    <el-form-item label="" label-width="10px" class="maibutt">
                        <!-- <el-button type="primary" size="small" @click="copyPermission">{{$t('devtable.fzqx')}}</el-button> -->
                        <el-button type="primary" size="small" @click="replacePermission">{{$t('devtable.thsb')}}</el-button>
                    </el-form-item>
                </div>
            </el-form>
			<el-form :model="form">
			    <div class="mainatai_row" style="display: block;">
			        <div class="maiinput" style="margin-bottom: 10px;">
			            <p>{{$t('devtable.zdsqjzsj')}}</p>
			            <!-- <el-input :placeholder="$t('devtable.qxz')" size="small"></el-input> -->
						<el-date-picker
						      v-model="value"
						      type="date"
						      :placeholder="$t('devtable.xzrqsj')"
							  @change="dataChange"
							  :picker-options="pickerOptions"
							  :disabled='dateInputState'
							  :default-value="new Date().getTime()">
						    </el-date-picker>
			        </div>
			        <div class="maiinput" style="margin-bottom: 10px;">
			            <p>{{$t('devtable.zdsqkl')}}</p>
			            <el-input  :placeholder="$t('devtable.qsr')" size="small" clearable v-model="auto_password" :disabled='passInputState'></el-input>
			        </div>
					<div>
						<p>{{$t('devtable.zdsqglan')}}</p>
						<el-switch v-model="switchState"></el-switch>
					</div>
			    </div>
			</el-form>
            <div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="confirmSettings">{{$t('devtable.qrsqsz')}}</el-button>
                <el-button type="primary" @click="syncDevicePermission">{{$t('devtable.sbb')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import methodlist from '../../../utils/methods'

export default {
    data () {
        return {
            dialogFormVisible: false,
            formLabelWidth:'70px',
            form: {
                old_serial: '',
                new_serial: ''
            },
            device_id: '',
            UserInfo:'',
			value:'',
			auto_key_time:'',
			auto_password:'',
			switchState:false,
			setInfo:{},
			pickerOptions:{
				disabledDate(time){
					return time.getTime() < Date.now() - 8.64e7
				}
			},
			passInputState:false,
			dateInputState:false
        }
    },
	watch:{
		switchState(newVal,oldVal){
			console.log(newVal,oldVal);
			if(newVal==true){
				this.passInputState = false
				this.dateInputState = false
			}else{
				this.passInputState = true
				this.dateInputState = true
			}
		}
	},
    mounted () {
      this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
    },
    methods: {
		
        copyPermission(){
            const params = {
                method: methodlist.ee_device_copy,
                from_serial_number: this.form.old_serial,
                serial_number: this.form.new_serial,                
                manager_id: this.UserInfo.manager_id
            }
            this.$serve(params).then(res => {          
                console.log(res);
                if(res.data.code == 0){
                    this.$message({
                        message: this.$t('devtable.fzcg'),
                        center: true,
                        type: 'success',
                        duration:2000,
                        offset:100
                    })      
                   //this.dialogFormVisible = false;
                }else{
                    //删除失败
                    if(res.data.code == -4){
                    this.$message({
                        message: this.$t('devtable.fzsbybycz'),
                        type: 'warning'
                    }) 
                }else{
                    this.$message({
                        message: this.$t('devtable.fzsb'),
                        type: 'warning'
                    }) 
                }
                }
            });           
        },
        replacePermission(){
            const params = {
                method: methodlist.ee_device_replace,
                from_serial_number: this.form.old_serial,
                serial_number: this.form.new_serial,                
                manager_id: this.UserInfo.manager_id
            }
            this.$serve(params).then(res => {          
                console.log(res);
                if(res.data.code == 0){
                    this.$message({
                        message: this.$t('devtable.thcg'),
                        center: true,
                        type: 'success',
                        duration:2000,
                        offset:100
                    })                          
                }else{
                    //删除失败
                    if(res.data.code == -4){
                        this.$message({
                            message: this.$t('devtable.thsbsbycz'),
                            type: 'warning'
                        }) 
                    }else{
                        this.$message({
                            message: this.$t('devtable.thsbb'),
                            type: 'warning'
                        }) 
                    }
                }
            });               
        },
		getSettings(id){
			let data = {
				method:'co.device.auto.key.get',
				id:id
			}
			this.$serve(data).then(res=>{
				console.log(res);
				if(res.data.code==0){
					this.setInfo = res.data.data
					console.log(this.setInfo);
					// this.value = this.setInfo.auto_key_time*1000
					if(this.setInfo.auto_key_time!=0){
						this.value = this.setInfo.auto_key_time*1000
					}
					this.auto_password = this.setInfo.auto_password
					// this.switchState = this.setInfo.is_open_auto_key
					if(this.setInfo.is_open_auto_key==0){
						this.switchState = false
						this.passInputState = true
						this.dateInputState = true
					}else{
						this.switchState = true
						this.passInputState = false
						this.dateInputState = false
					}
				}
			})
		},
		dataChange(e){
			this.auto_key_time = (e.getTime())/1000+86399
			console.log(this.auto_key_time);
		},
		setAuthorization(){
			if(this.switchState){
				if(this.auto_key_time==''){
					this.$message({
					    message:this.$t('devtable.xzrqsj'),
					    type: 'warning'
					})
					return
				}
			}
			
			let data = {
				method:'co.device.auto.key.set',
				id:this.device_id,
				auto_key_time:this.auto_key_time,
				auto_password:this.auto_password,
				is_open_auto_key:this.switchState?'1':'0'
			}
			this.$serve(data).then(res=>{
				console.log(res);
				if(res.data.code==0){
					this.$message({
					    message:this.$t('devtable.szcg'),
					    type: 'success'
					})
					this.dialogFormVisible = false;
				}else{
					this.$message({
					    message:this.$t('devtable.szsb'),
					    type: 'warning'
					}) 
				}
			})
		},
		confirmSettings(){
			this.setAuthorization()
		},
        syncDevicePermission(){
			console.log(this.device_id);
            const params = {
                method: methodlist.ee_device_syn,
                id: this.device_id,
                agent_id: this.UserInfo.agent_id,                
                manager_id: this.UserInfo.manager_id
            }
            this.$serve(params).then(res => {          
                console.log(res);
                if(res.data.code == 0){
                    this.$message({
                        message:this.$t('devtable.tbcg'),
                        center: true,
                        type: 'success',
                        duration:2000,
                        offset:100
                    })      
                    this.dialogFormVisible = false;
                }else{
                    //删除失败
                    if(res.data.code == -3){
                        this.$message({
                            message:this.$t('devtable.tbsbmyqx'),
                            type: 'warning'
                        }) 
                    }else{
                        this.$message({
                            message: this.$t('devtable.tsb'),
                            type: 'warning'
                        }) 
                    }
                }
            });
			
        },
        transferCurrDeviceInfo(devInfo){
            this.form.old_serial = devInfo.serial_number;
            this.device_id = devInfo.id;
        }
    }
}
</script>
<style lang="less" scoped>
.mainatai_row{
    display: flex;
    .maibutt{
        display: flex;
        margin-top: 24px;
    }
    .maiinput{
        width: 140px;
    }
    div{
        margin-right: 10px;
        p{
            margin-bottom: 10px
        }
    };
}
.mauntacont /deep/ .el-dialog__body{
    padding: 20px 20px;
}
</style>