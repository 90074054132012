<template>
  <div class="devi">
    <el-dialog :title="titlename"  width="580px" append-to-body :visible.sync="dialogFormVisible">
      <el-form :model="form" size="small">
        <el-form-item :label="$t('devtable.sbxlh')" style="width:80%"  :label-width="formLabelWidth" >
          <div class="shexul">
              <el-input  v-model="form.serial_number" autocomplete="off"></el-input>
              <el-button type="primary" size="small" class="queryt" @click="deviceSearch">{{this.$t('devtable.cx')}}</el-button>
          </div>
        </el-form-item>
        <el-form-item :label="this.$t('devtable.sblxH')" style="width:60%"  :label-width="formLabelWidth" >
          <el-input v-model="form.device_face" autocomplete="off" readonly></el-input>
        </el-form-item>

        <!-- <el-form-item :label="$t('devtable.changj')" :label-width="formLabelWidth" >           -->
  <!-- <el-checkbox class="checkeds" v-model="form.keygen"></el-checkbox> -->
    <!-- <el-select v-model="form.factory" placeholder=""> -->
      <!-- <el-option :label="$t('devtable.hs')" :value="$t('devtable.hs')"></el-option> -->
      <!-- <el-option :label="$t('devtable.xc')" :value="$t('devtable.xc')"></el-option> -->
  <!-- </el-select> -->
<!-- </el-form-item> -->

        <el-form-item :label="$t('devtable.sfzcj')" :label-width="formLabelWidth" >          
          <!-- <el-checkbox class="checkeds" v-model="form.keygen"></el-checkbox> -->
            <el-select v-model="form.is_register" placeholder="">
              <el-option :label="$t('devtable.s')" value="1"></el-option>
              <el-option :label="$t('devtable.f')" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('devtable.azwz')" style="width:60%" :label-width="formLabelWidth" >
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('devtable.bmqx')" :label-width="formLabelWidth">
          <wlTreeSelect
          leaf
			:placeholder="$t('devtable.qxz')"
          width="280"
          checkbox
          :data="treedata"
          @change="hindleChanged"
          v-model="mineStatus"
          :defaultSelect='true'
          :showTotal="2"
        ></wlTreeSelect>
          <!-- <el-select v-model="mineStatus" placeholder=" " size="small" multiple>
          <el-option :value="mineStatus"  style="height: auto">
            <el-tree
                default-expand-all
                :data="treedata"
                check-strictly
                show-checkbox
                node-key="id"
                :default-expanded-keys="initIdList"
                :default-checked-keys="initIdList"
                ref="tree" highlight-current :props="defaultProps"
                @check-change="handleCheckChange"
            >
            </el-tree>
            </el-option>
          </el-select> -->
          <!-- <Select ref="select" :valuet='valuet' @handleCheckChange="handleCheckChange"></Select> -->
        </el-form-item>
        <el-form-item :label="$t('devtable.kmzt')" :label-width="formLabelWidth">
          <el-select v-model="form.unlock_state" placeholder="">
            <el-option :label="$t('devtable.j')" value="0"></el-option>
            <el-option :label="$t('devtable.c')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('devtable.kmms')" :label-width="formLabelWidth">
          <el-select v-model="form.lock_mode" placeholder="">
            <el-option :label="$t('devtable.ysms')" value="0"></el-option>
            <el-option :label="$t('devtable.ckms')" value="1"></el-option>
            <el-option :label="$t('devtable.cbms')" value="2"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- 
        <el-form-item :label="$t('devtable.kmys')" :label-width="formLabelWidth">
          <el-select v-model="form.lock_delay" placeholder="">
            <el-option :label="0.5+$t('devtable.miao')" value="0"></el-option>
            <el-option :label="1+$t('devtable.miao')" value="1"></el-option>
            <el-option :label="3+$t('devtable.miao')" value="2"></el-option>
            <el-option :label="10+$t('devtable.miao')" value="3"></el-option>
            <el-option :label="20+$t('devtable.miao')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <div class="extend">
          <el-button type="primary" @click="handlExtend" size="small">{{$t('devtable.kzcs')}}</el-button>
        </div>
        <div class="extend_row" v-if="exflag">
            <div class="extendedparameters">
              <el-checkbox class="checkeds" v-model="form.living">{{$t('devtable.htjc')}}</el-checkbox>
              <el-checkbox class="checkeds" v-model="form.upload_intime">{{$t('devtable.sssc')}}</el-checkbox>
              <el-checkbox class="checkeds" v-model="form.mess_close">{{$t('devtable.gbxxk')}}</el-checkbox>
              <el-checkbox class="checkeds" v-model="form.fill_in_light">{{$t('devtable.bgcl')}}</el-checkbox>
              <el-checkbox class="checkeds" v-model="form.record_pic">{{$t('devtable.bjlzp')}}</el-checkbox>
              <el-checkbox class="checkeds" v-model="form.upload_pic">{{$t('devtable.bsczp')}}</el-checkbox>
              <el-checkbox class="checkeds" v-model="form.qr_code">{{$t('devtable.ewmsb')}}</el-checkbox>
          </div>
          <div class="extendhelp">
              <el-form-item :label="$t('devtable.sbms')" label-width="120px">
                <el-select size='mini' v-model="form.recognition_mode" placeholder="">
                  <el-option label="1:1" value="0"></el-option>
                  <el-option label="1:N" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" label-width="" class="dnr">
                <el-select size='mini' v-model="form.many_people" placeholder="">
                  <el-option :label="$t('devtable.dr')" value="0"></el-option>
                  <el-option :label="$t('devtable.ddr')" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="4G" label-width="45px">
                <el-select size='mini' v-model="form.onoff_4G" placeholder="">
                  <el-option :label="$t('devtable.kq')" value="0"></el-option>
                  <el-option :label="$t('devtable.gb')" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="display:none" label="" label-width="10px">
                <el-button type="primary" size="mini" @click="handlExtendhelp">设备屏保</el-button>
              </el-form-item>
          </div>
          <div class="bingbao_wap" v-if="bflag">
              <div class="bingbao">
                <el-form-item label="类型" label-width="40px">
                  <el-select size='mini' v-model="form.sp_type" placeholder="请选择活动区域">
                    <el-option label="1:1" value="shanghai"></el-option>
                    <el-option label="N:1" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="行数" label-width="40px">
                  <el-select size='mini' v-model="form.sp_line" placeholder="请选择活动区域">
                    <el-option label="1:1" value="shanghai"></el-option>
                    <el-option label="N:1" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="位置" label-width="40px">
                  <el-select size='mini' v-model="form.sp_position" placeholder="请选择活动区域">
                    <el-option label="1:1" value="shanghai"></el-option>
                    <el-option label="1:N" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="字体" label-width="40px">
                  <el-select size='mini' v-model="form.sp_font" placeholder="请选择活动区域">
                    <el-option label="1:1" value="shanghai"></el-option>
                    <el-option label="N:1" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="binghu">
                <el-form-item label="默认图片" label-width="80px" class="morimg">
                  <el-select size='mini' v-model="form.sp_img" placeholder="请选择活动区域">
                    <el-option label="1:1" value="shanghai"></el-option>
                    <el-option label="N:1" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="自定义图片" label-width="90px">
                    <el-button type="primary" size="mini">浏览</el-button>
                </el-form-item>
                <p>(图像像素480*850)</p>
              </div>
          </div>

        </div>
         -->
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button  type="primary" class="dilogbuttom" @click="deviceAddEdit">{{this.$t('tablename.qd')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Select from '@/components/selectree/select3'
import methodlist from '../../../utils/methods.js'
import {devsearch} from '../../../api/uesr'
import {Qiyebum,todeepName} from '@/utils/index'
export default {
  name: 'Deviadd',
  props:{
    titlename:String,
    label: "name",
    value: "id",
  },
  components:{
    Select
  },   
  data () {
    return {
      mineStatus: [],
      treedata: [],
      defaultProps: {
          children: "children",
          label: "name",
          value:'id',
          disabled: "is_valid"
      },
      initIdList:[],


      valuet:null,
      UserInfo:'',
      form: {
        factory:'',
        serial_number: '',
        device_face: '',
        name: '',
        is_register: '1',
        //position: '',
        dept_id: '',
        unlock_state: '',
        lock_mode: '',
        lock_delay: '',
        //扩展参数      
        living: true,
        upload_intime: false,
        mess_close: false,
        fill_in_light: false,
        record_pic: false,
        upload_pic: false,
        qr_code: false,
        recognition_mode: '',
        many_people: '',        
        onoff_4G: '',
        //屏保信息
        sp_type: '',
        sp_line: '',
        sp_position:'',
        sp_font: '',
        sp_img: ''        
      },
      //设备信息
      dev_info: {
            mac: '', //mac
            device_type: '',//设备类型 读头门禁
            btkey_type: '', //蓝牙key类型
            btkey: '', //蓝牙key
            device_no: '', //设备序号
            password: '', //设备mqtt密码
            serial: '', //设备mqtt账号
            support_face: '',//是否支持人脸
            deviceId: '',//设备ID
            algorithm: '',//机器平台,
            scan_open:''
        },
      dev_edit: {
        device_id: ''
      },
      formLabelWidth: '100px',
      dialogFormVisible:false,
      bflag:false, // 设备屏保
      exflag:false, // 扩展参数
      add_edit_flag: 0,

      
    }
  },

  mounted () {

    this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
    if(this.UserInfo.language ='en'){
        this.formLabelWidth = '150px'
    }
    this.form.recognition_mode='1';
    this.form.many_people='0';
    this.form.onoff_4G = "1";

    this.form.unlock_state = '0';
    this.form.lock_mode = '0';
    this.form.lock_delay = '2';
    this.getDeptDataList();
  },

  methods:{
    handlExtendhelp(){
      this.bflag = !this.bflag
    },
    handlExtend(){
      this.exflag = !this.exflag
    },


    //获取企业架构
        getDeptDataList(){
            const params = {
                method: methodlist.ee_dept_list,
                agent_id:this.UserInfo.agent_id,
                manager_id:this.UserInfo.manager_id,
            }
            this.$serve(params).then(res => {    
                var result = [];
                if(res.data.data != null)
                result.push(this.getFirstObj(res.data.data))
                this.treedata=Qiyebum(result)
            })
        },
        // 工具函数
        getFirstObj(obj){
            for(var key in obj){
                return obj[key];
            }      
        },
        hindleChanged(val) {
          console.log(`2`,val);
          // console.log(this.mineStatus);
          let dept_id = []
          val.forEach(el=>{
            dept_id.push(el.id)
          })
          this.form.dept_id = dept_id.join(',')
        },
        //获取企业部门id
        // handleCheckChange(data, checked, node) {
          
        //     let res = this.$refs.tree.getCheckedNodes(false, true); //true，1. 是否只是叶子节点 2.选择的时候不包含父节点）
        //     let arrLabel = [];
        //     let dept_idlist = []
        //     console.log(res, 'res')
        //     res.forEach(item => {
        //         arrLabel.push(item.name)
        //         dept_idlist.push(item.id)
        //     });
        //     this.mineStatus = arrLabel;
        //     this.dept_id = data.id
        //    this.form.dept_id =dept_idlist.join(",");
        // },
    
    // 获取企业部门id
    // handleCheckChange(val){
    //   console.log(`99`,val)
    //   this.form.dept_id = val.join(",");
    //   console.log(`99`,this.form.dept_id)
    // },    
    //查询设备
    deviceSearch(){
      console.log(this.dev_info);
      const params = {
          method: methodlist.ee_device_search,
          serial_number:this.form.serial_number
      }
      devsearch(params).then(res => {
        if(res.data.code==0){
           this.dev_info = res.data.data;
   
            if(this.dev_info.support_face == 1){
              this.form.device_face = this.$t('devtable.rlj');
            }else{
              this.form.device_face = this.$t('devtable.frlj');
            }
        }else{
          this.$message({
            type:'error',
            message:this.$t('devtable.sbbcz')
          })
        }
      });
    },


    getFirstObj(obj){
      for(var key in obj){
          return obj[key];
      }
    },
    deviceAddEdit(){
      if(this.form.device_face.length == 0){
            this.$message({
              message: this.$t('devtable.qxcxsb'),
              center: true,
              type: 'warning',
              duration:2000,
              offset:100
            });
        return false;
      }
      if(!this.form.dept_id){
         this.$message({
              message: this.$t('devtable.qxxzbm'),
              center: true,
              type: 'warning',
              duration:2000,
              offset:100
            });
        return false;
      }

      var params_set = (this.form.living == true ? 1 : 0) + ','
        + (this.form.upload_intime == true ? 1: 0) + ','
        + (this.form.mess_close == true ? 1: 0) + ','
        + (this.form.fill_in_light == true ? 1: 0) + ','
        + (this.form.record_pic == true ? 1: 0) + ','
        + (this.form.upload_pic == true ? 1: 0) + ','
        + (this.form.qr_code == true ? 1: 0) + ','
        + this.form.recognition_mode + ','
        + this.form.many_people + ','
        + this.form.onoff_4G ;
      var params = {
          method: methodlist.ee_device_add,
          serial_number: this.form.serial_number,
          name: this.form.name,
          agent_id: this.UserInfo.agent_id,
          unlock_state: this.form.unlock_state,
          lock_mode: this.form.lock_mode,
          lock_delay: this.form.lock_delay,
          params_set: params_set,
          serial: this.dev_info.serial,
          scan_open:this.dev_info.scan_open,
          password: this.dev_info.password,
          support_face: this.dev_info.support_face,
          mac: this.dev_info.mac,
          btkey_type: this.dev_info.btkey_type,
          btkey: this.dev_info.btkey,
          device_no: this.dev_info.device_no,
          algorithm: this.dev_info.algorithm,
          device_type: this.dev_info.device_type,
          dept_id: this.form.dept_id,
          is_register: this.form.is_register,
          factory:this.form.factory
      }
      console.log(`0`,this.form.dept_id)
      // return false
      if(this.add_edit_flag == 1){
        params = {
          method: methodlist.ee_device_edit,          
          name: this.form.name,
          agent_id: this.UserInfo.agent_id,
          unlock_state: this.form.unlock_state,
          lock_mode: this.form.lock_mode,
          lock_delay: this.form.lock_delay,
          params_set: params_set,
          id: this.dev_edit.device_id,
          dept_id: this.form.dept_id,
          is_register: this.form.is_register,
          factory:this.form.factory
        }
      }
      if(!params.name){
          this.$message({
            type:'error',
            message:this.$t('devtable.qtxazwz')
          })
          return false
      }
        this.$serve(params).then(res => {          
          console.log(res);
          if(res.data.code == 0){
            var message=this.$t('devtable.sbtjcg')
            if(this.add_edit_flag == 1)
              message=this.$t('devtable.sbxgcg')
            this.$message({
                message: message,
                center: true,
                type: 'success',
                duration:2000,
                offset:100
              });
            this.dialogFormVisible = false
            // this.form.dept_id =""
            // this.$nextTick(function () {
            //   //Dom更新完毕
            //   this.$refs.select.setCheckedkeys();
            // });
            this.addDeviceSuccess();
            
          }else{
             if(res.data.code == -4){
              this.$message({
                message: this.$t('devtable.sbycz') + res.data.message + this.$t('devtable.ytjlgsb'),
                center: true,
                type: 'warning',
                duration:2000,
                offset:100
              });
             }else{
               //设备添加失败及原因
                this.$message({
                message: this.$t('devtable.sbtjsb'),
                center: true,
                type: 'warning',
                duration:2000,
                offset:100
              });
             }
          }
      });

    },
    // //从设备列表获取编辑信息
    getEditData(data,opFlag){
      
        if(opFlag == 1){
          this.add_edit_flag = 1;
     
          this.dev_edit.device_id = data.id;
          const params = {
            method: methodlist.ee_device_get,
            id: data.id
          }
          this.mineStatus =[]
          this.$serve(params).then(res => {          
            this.initIdList=res.data.data.dept_id.split(',')
            // this.$refs.tree.setCheckedKeys([])
            // this.$refs.select.hanhdepid(res.data.data.dept_id)
            this.form.serial_number = res.data.data.serial_number;
            this.form.name = res.data.data.name;
            this.form.unlock_state = ''+res.data.data.unlock_state;
            this.form.lock_mode = ''+res.data.data.lock_mode;
            this.form.dept_id = res.data.data.dept_id;
            this.mineStatus = res.data.data.dept_id.split(',')
            let arrPa = res.data.data.params_set.split(','); // 分割参数
            this.form.living = (arrPa[0] === '1') ? true : false;
            this.form.upload_intime = (arrPa[1] === '1') ? true : false;
            this.form.mess_close = (arrPa[2] === '1') ? true : false;
            this.form.fill_in_light = (arrPa[3] === '1') ? true : false;
            this.form.record_pic = (arrPa[4] === '1') ? true : false;
            this.form.upload_pic = (arrPa[5] === '1') ? true : false;
            this.form.qr_code = (arrPa[6] === '1') ? true : false;
            this.form.recognition_mode = '' + arrPa[7];
            this.form.many_people = '' + arrPa[8];
            this.form.onoff_4G = '' + arrPa[9];
            this.form.is_register = res.data.data.is_register.toString()
            this.deviceSearch();
          });
        }else{
          this.add_edit_flag = 0;
        }
    },
    addDeviceSuccess(){
      this.$emit('addDeviceSuccess')
    }
  }
}
</script>
<style lang="less" scoped>
.el-form{
  width: 92%;
  margin-top: 10px;
  .shexul{
    display: flex;
    .queryt{
      margin-left: 20px;
    }
  }
  .extend{
    width:100px;
    margin: 0 auto;
  }
  .extend_row{
    .extendedparameters{
        justify-content: center;
        display: flex;
        flex-direction: row;
        flex-wrap:wrap ;
        margin-left:25px;
        margin-top: 20px;
      .checkeds{
        display: flex;
        margin-top: 20px;
        align-items:center;
        justify-content:center;
        align-content: flex-start;
      }
    }
  }
  .extendhelp{
    display: flex;
    margin-top: 20px;
    .dnr{
      margin-left: 20px;
    }
  }
  .bingbao_wap{
    margin-left:40px;
    .bingbao{
      display: flex;
      .el-form-item{
        margin-left: 10px;
      }
    }
    .binghu{
      display: flex;
      p{
        margin-left: 10px;
        font-size: 14px;
        line-height: 40px;
        color: red;
      }
      .morimg {
        width: 35%;
        margin-right: 10px;
      }
    }
  }
  
}
.extendedparameters:after{
  content: '';
  width: 112px;
}

.el-dialog__wrapper /deep/ .el-dialog__body{
    padding: 10px 20px;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
     background-color: #fff;
}
.dialog-footer{
    text-align: center!important;
}
</style>
