<template>
    <el-select v-model="mineStatus"  placeholder="请选择 " size="small" multiple>
        <el-option :value="mineStatus"  style="height: auto">
            <el-tree
                default-expand-all
                :data="treedata"
                check-strictly
                show-checkbox
                node-key="id"

                :default-checked-keys="initIdList"
                ref="tree" highlight-current :props="defaultProps"
                @check-change="handleCheckChange"
            >
            </el-tree>

        </el-option>
    </el-select>
</template>
<script>
import methodlist from '@/utils/methods'
import {Qiyebum,todeepName} from '@/utils/index'
export default {
    data(){
        return{
            mineStatus: [],
            treedata: [],
            defaultProps: {
                children: "children",
                label: "name",
                value:'id',
                disabled: "is_valid"
            },
            UserInfo:'',
            initIdList:[],
            valuet:""
        }
    },
    watch:{
        treedata:function(nd,od){
            console.log(`9099`,nd,od)
        }
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getDeptDataList()
        
    },

    
    methods:{
        //处理dep_id
        hanhdepid(data){
             this.mineStatus = todeepName(data,this.treedata)
             console.log(`5`,this.mineStatus )
        },

        // //根据部门id获取部门名称
        // getDeptNameWithId(id,object){
           
        //     for(var i in object){
        //         console.log(`3`,object[i].id)
        //         if(id == object[i].id){
        //             this.initIdList.push(object[i].id)
        //             this.mineStatus.push(object[i].name)
                     
        //             return false;
                    
        //         }
        //         if(object[i].children){
        //             this.getDeptNameWithId(id,object[i].children);
        //         }
               
        //     }
        //     console.log(`1`,this.initIdList)
        //      console.log(`2`,this.mineStatus)
        // },
       
        //获取企业架构
        getDeptDataList(){
            const params = {
                method: methodlist.ee_dept_list,
                agent_id:this.UserInfo.agent_id,
                manager_id:this.UserInfo.manager_id,
            }
            this.$serve(params).then(res => {    
                var result = [];
                if(res.data.data != null)
                result.push(this.getFirstObj(res.data.data))
                this.treedata=Qiyebum(result)
            })
        },
        // 工具函数
        getFirstObj(obj){
            for(var key in obj){
                return obj[key];
            }      
        },
        //获取企业部门id
        handleCheckChange(data, checked, node) {
            let res = this.$refs.tree.getCheckedNodes(false, true); //true，1. 是否只是叶子节点 2.选择的时候不包含父节点）
            let arrLabel = [];
            let dept_idlist = []
            console.log(res, 'res')
            res.forEach(item => {
                arrLabel.push(item.name)
                dept_idlist.push(item.id)
            });
            this.mineStatus = arrLabel;
            this.dept_id = data.id
            this.$emit('handleCheckChange',dept_idlist)
        },
        
    }
}
</script>
<style scoped>
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
     background-color: #fff;
}
</style>