<template>
    <div class="namefrom">
        <el-dialog :title="$t('devtable.scsbt')" append-to-body width="20%"  :visible.sync="dialogname">
            <el-form :model="form">
                <el-form-item :label="$t('devtable.yhm')" :label-width="formLabelWidth">
                <el-input v-model="form.name_input" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.mm')" :label-width="formLabelWidth">
                <el-input v-model="form.pass_input" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handialogname(form)">{{$t('tablename.qd')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return{
            formLabelWidth:'100px',
            dialogname:false,
            form: {
                name_input: '',
                pass_input: ''
            },
        }
    },
    mounted () {
      this.UserInfo = JSON.parse(window.localStorage.getItem('user'));  
    },
    methods:{
        handialogname(data){
            console.log(data)
            this.$emit('handialogname',data)
        }
    },
}
</script>
<style lang="less" scoped>
.namefrom /deep/ .el-dialog__body{
    padding: 10px 10px;
}
</style>